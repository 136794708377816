import { createRouter, createWebHashHistory } from 'vue-router'

// 获取语言类型
import i18n from '@/locals/index'

const router = createRouter({
  history: createWebHashHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
  routes: [
    {
      path: '/',
      redirect: '/index',
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ '../views/LoginPage.vue'),
      meta:{
        // title:i18n.global.t('common.Iknow')
        // title:'666'
        title:i18n.global.t('Web_title.title1')
      }
    },
    {
      path: '/index',
      name: 'index',
      redirect: '/index/firstTerm',
      component: () => import('../views/HomePage/HomePage.vue'),
      children:[
        {
          //首页
          path:'/index/firstTerm',
          name:'firstTerm',
          component: () => import('../views/HomePage/firstTerm/firstTerm.vue'),
          meta:{
            title:i18n.global.t('Web_title.title2')
          }
        },
        {
          //DiyEb
          path:'/index/diyEb',
          name:'diyEb',
          component: () => import('../views/HomePage/diyEb/diyEb.vue'),
          meta:{
            title:i18n.global.t('Web_title.title3')
          }
        },
        {
          //NIW
          path:'/index/diyNIW',
          name:'diyNIW',
          component: () => import('../views/HomePage/NIWpage/NIWPage.vue'),
          meta:{
            title:i18n.global.t('Web_title.title4')
          }
        },
        {
          path:'/index/audit',
          name:'audit',
          component: () => import('../views/HomePage/audit/audit.vue'),
          meta:{
            title:i18n.global.t('Web_title.title5')
          }
        },
        {
          path:'/index/translate',
          name:'translate',
          component: () => import('../views/HomePage/translate/translate.vue'),
          meta:{
            title:i18n.global.t('Web_title.title6')
          }
        },
        {
          path:'/index/aboutUs',
          name:'aboutUs',
          component: () => import('../views/HomePage/aboutUs/aboutUs.vue'),
          meta:{
            title:i18n.global.t('Web_title.title7')
          }
        },
        {
          path:'/index/immigrant',
          name:'immigrant',
          component: () => import('../views/HomePage/immigrant/immigrant.vue'),
          meta:{
            title:i18n.global.t('Web_title.title8')
          }
        },
        {
          path:'/index/answer',
          name:'answer',
          component: () => import('../views/HomePage/answer/answer.vue'),
          meta:{
            title:i18n.global.t('Web_title.title9')
          }
          // children:[
          //   {
          //     path:'/index/answer/answerList',
          //     name:'answerList',
          //     component: () => import('../views/HomePage/answer/compontent/answerList/answerList.vue'),
          //   },
          //   {
          //     path:'/index/answer/particulars',
          //     name:'particulars',
          //     component: () => import('../views/HomePage/answer/compontent/particulars/particulars.vue'),
          //   }
          // ]
        },
      ]
    },
    {
      path: '/estimate',
      name: 'estimate',
      component: () => import('../views/FreeEstimate/FreeEstimate.vue'),
      meta:{
        title:i18n.global.t('Web_title.title10')
      }
    },
    {
      path: '/HKestimate',
      name: 'HKestimate',
      component: () => import('@/views/FreeEstimate/HKEstimate.vue'),
      meta:{
        title:i18n.global.t('Web_title.title11')
      }
    },
    {
      path: '/payService',
      name: 'payService',
      component: () => import('../views/PayService/PayService.vue'),
      meta:{
        title:i18n.global.t('Web_title.title12')
      }
    },
    {
      path: '/personalCenter',
      name: 'personalCenter',
      redirect: '/personalCenter/personMessage',
      component: () => import('../views/PersonalCenter/PersonalCenter.vue'),
      meta:{
        title:i18n.global.t('Web_title.title13')
      },
      children:[
        {
          //个人信息
          path: '/personalCenter/personMessage',
          name: 'personMessage',
          component: () => import('../views/PersonalCenter/personMessage/personMessage.vue'),
          meta:{
            title:i18n.global.t('Web_title.title14')
          },
        },
        {
          // 消息提醒
          path: '/personalCenter/messageRemind',
          name: 'messageRemind',
          component: () => import('../views/PersonalCenter/messageRemind/messageRemind.vue'),
          meta:{
            title:i18n.global.t('Web_title.title15')
          },
        },
        {
          // 我的计划
          path: '/personalCenter/myPlan',
          name: 'myPlan',
          component: () => import('../views/PersonalCenter/myPlan/myPlan.vue'),
          meta:{
            title:i18n.global.t('Web_title.title16')
          },
        },
        {
          // 购买记录
          path: '/personalCenter/purchaseRecord',
          name: 'purchaseRecord',
          component: () => import('../views/PersonalCenter/purchaseRecord/purchaseRecord.vue'),
          meta:{
            title:i18n.global.t('Web_title.title17')
          },
        }
      ]
    },
    {
      path: '/EB-1A',
      name: 'EB-1A',
      component: () => import('../views/DiyEb/DiyEb.vue'),
      meta:{
        title:i18n.global.t('Web_title.title18')
      },
    },
    {
      path: '/NIW',
      name: 'NIW',
      component: () => import('../views/DiyNIW/DiyNIW.vue'),
      meta:{
        title:i18n.global.t('Web_title.title19')
      },
    },
    {
      path: '/guidance',
      name: 'guidance',
      component: () => import('@/views/guidance/guidance.vue'),
      meta:{
        title:i18n.global.t('Web_title.title20')
      },
    },
    {
      path: '/previewToPdf',
      name: 'previewToPdf',
      component: () => import('@/views/previewPDF/index.vue'),
      meta:{
        title:i18n.global.t('Web_title.title21')
      },
    },
    {
      path:'/viewPreview',
      name:'viewPreview',
      component:() => import('@/views/ViewPreview/ViewPreview.vue'),
      meta:{
        title:i18n.global.t('Web_title.title22')
      },
    },
    {
      path:'/onePagePreview',
      name:'onePagePreview',
      component:() => import('@/views/OnePagePreview/OnePagePreview.vue'),
      meta:{
        title:i18n.global.t('Web_title.title23')
      },
    },
    // 隐私协议
    {
        path: '/privacyFile',
        component: ()=>import('@/views/privacyFile/privacyFile.vue'),
        meta: {        
            title:'隐私政策'
        },
    },
    // 推广页面
    {
      path: '/promotion',
      component: ()=>import('@/views/promotion/promotion.vue'),
      meta: {        
          title:'CasePass'
      },
  }
  ]
})

export default router